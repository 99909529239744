import React from 'react';
import logo from './logo.svg';
import './App.scss';

const title = "Girafprodukter";
const colorClasses = [
  "red", "blue", "green", "yellow", "purple"
]

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-header__inner">
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >{title.split("").map((ch, i) => {
            return (<span key={i} className={colorClasses[i % colorClasses.length]}>{ch}</span>)
          })}</a>
          <img className="App-logo" src="/logo.svg" />
        </div>
      </header>
      <div className="container">
        <div className="image-text-container">
          <img className="main-image" src="/serviet.png" />
          <div className="main-text">
            <p>Girafprodukter er et dansk firma, som designer, producerer og markedsfører lege-lære-produkter til før-skole-børn fra 2 års alderen og skolebørn op til 10-12 års alderen.</p>
            <p>Vores produkter består af dækkeservietter, plakater, spil, bøger og postkort.</p>
            <a className="contact-link" href="mailto:hfj@girafprodukter.dk">Skriv til hfj@girafprodukter.dk</a>
          </div>
        </div>
        {/* <img className="main-image main-image--2" src="/serviet.png" /> */}
      </div>
      <div className="contact-info">
        <ul className="contact-info__list">
          <li>Hans Færch-Jensen</li>
          <li>Girafprodukter Aps.</li>
          <li>Paul-Petersensvej 3</li>
          <li>DK-2820 Gentofte</li>
          <li>Telefon 61 70 28 13</li>
          <li>hfj@girafprodukter.dk</li>
        </ul>
      </div>
    </div>
  );
}

export default App;
